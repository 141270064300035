import { SearchPayload } from "@/store/searchStore";
import { fetchPost } from "./apiBase";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useInfiniteQuery } from "@tanstack/react-query";
import { NewsIconFilters } from "@/types/util";
import useJwt from "./session";
import { vercelEnv } from "./environment";

const isProduction = vercelEnv === "production";

const SEARCH_API_URL =
    (process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
        (isProduction ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`)) + "/search";

export enum LINKERS {
    OR = "OR",
    AND = "AND",
}

export type searchByTagsPayload = {
    query1: string;
    query2?: string;
    link?: LINKERS;
    from?: number;
    filter?: string;
    filterForLast7Days: boolean;
};

const searchNewsByTags = async (neededFilterSet, searchPayload: SearchPayload) => {
    const res = await fetchPost(SEARCH_API_URL, { ...searchPayload, neededFilterSet });
    res.total = res.total?.value || 0;
    return res;
};

export const useSearchNewsByTags = (neededFilterSet: any, searchPayload: SearchPayload) => {
    return useInfiniteQuery({
        queryKey: ["searchNewsByTags", neededFilterSet, searchPayload],
        queryFn: ({ pageParam = 0 }) =>
            searchNewsByTags(neededFilterSet, { ...searchPayload, from: pageParam as number }),
        getNextPageParam: (lastPage, allPages) => {
            const totalResults = lastPage?.total || 0; // Total number of available results
            const fetchedResults = allPages.flatMap((page) => page.results).length;
            return fetchedResults < totalResults ? allPages.length * 30 : undefined;
        },
        initialPageParam: 0,
        enabled:
            !!searchPayload.headline ||
            !isEmpty(searchPayload.tags) ||
            !isEmpty(searchPayload.assets) ||
            !isEmpty(searchPayload.sources),
    });
};

const fetchNewsByIconFilter = async ({
    filter = NewsIconFilters.join("%20OR%20"),
    from = 0,
    time = [],
    primarySourcesOnly = false,
}: {
    filter?: string;
    from?: number;
    time?: number[];
    primarySourcesOnly?: boolean;
}) => {
    const res = await fetchPost(`${SEARCH_API_URL}/search-news-by-icon`, {
        filter,
        from,
        time,
        primarySourcesOnly,
    });
    res.total = res.total?.value || 0;
    return res;
};

export const useFetchNewsByIconFilter = ({
    filter = NewsIconFilters.join("%20OR%20"),
    time = [],
    primarySourcesOnly = false,
}: {
    filter?: string;
    time?: number[];
    primarySourcesOnly?: boolean;
}) => {
    return useInfiniteQuery({
        queryKey: ["newsByIconFilter", filter, time, primarySourcesOnly],
        queryFn: ({ pageParam = 0 }) =>
            fetchNewsByIconFilter({ filter, from: pageParam as number, time, primarySourcesOnly }),
        getNextPageParam: (
            lastPage: {
                query?: string;
                results?: any[];
                total?: number;
            },
            allPages,
        ) => {
            const totalResults = lastPage?.total || 0; // Total number of available results
            const fetchedResults = allPages.flatMap((page) => page.results).length;
            return fetchedResults < totalResults ? allPages.length * 30 : undefined;
        },
        initialPageParam: 0,
    });
};

const fetchBookmarkedNews = async ({
    filter = NewsIconFilters.join("%20OR%20"),
    from = 0,
    time = [],
    primarySourcesOnly = false,
}: {
    filter?: string;
    from?: number;
    time?: number[];
    primarySourcesOnly?: boolean;
}) => {
    const res = await fetchPost(`${SEARCH_API_URL}/get-bookmarked-news`, {
        filter,
        from,
        time,
        primarySourcesOnly,
    });

    return res;
};

export const useBookmarkedNews = ({
    filter = NewsIconFilters.join("%20OR%20"),
    time = [],
    primarySourcesOnly = false,
}: {
    filter?: string;
    time?: number[];
    primarySourcesOnly?: boolean;
}) => {
    const jwt = useJwt();

    return useInfiniteQuery({
        queryKey: ["bookmarkedNews", filter, time, primarySourcesOnly],
        queryFn: ({ pageParam = 0 }) =>
            fetchBookmarkedNews({ filter, from: pageParam as number, time, primarySourcesOnly }),

        getNextPageParam: (
            lastPage: {
                query?: number;
                results?: any[];
                total?: { value: number; relation: string };
            },
            allPages,
        ) => {
            const totalResults = lastPage?.total?.value || 0; // Total number of available results
            const fetchedResults = allPages.flatMap((page) => page.results).length;

            return fetchedResults < totalResults ? allPages.length * 30 : undefined;
        },
        initialPageParam: 0,
        enabled: !!jwt,
    });
};

const searchSingleTag = async ({ tag, from }: { tag: string; from?: number }) => {
    const res = await fetchPost(`${SEARCH_API_URL}/search-single-tag`, {
        tag,
        from,
    });

    return res;
};

export const useSearchSingleTag = ({ tag }: { tag: string }) => {
    return useInfiniteQuery({
        queryKey: ["singleTag", tag],
        queryFn: ({ pageParam = 0 }) => searchSingleTag({ tag, from: pageParam }), // Pass `pageParam` correctly
        enabled: !!tag,
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage || lastPage.length === 0) return undefined; // Stop fetching if no more results

            const nextFrom = allPages.length * 10;
            if (nextFrom + 10 > 100) return undefined;
            // Top hits result window is too large, the top hits aggregator [top_tags]'s from + size must be less than or equal to: [100] but was [110].
            // This limit can be set by changing the [index.max_inner_result_window] index level setting.
            // the error above was returned before the fix above it

            const currentPageSize = lastPage.response.reduce(
                (total, bucket) => total + (bucket.top_tags?.hits?.hits.length || 0),
                0,
            );

            if (currentPageSize < 10) return undefined; // Stop if fewer than 10 results (pagination limit)

            return allPages.length * 10; // Increment `from` by 10 per page
        },
        initialPageParam: 0,
    });
};

const searchTagsAcrossFields = async (searchPayload: searchByTagsPayload) => {
    const { query1, query2, link, from, filter, filterForLast7Days } = searchPayload;
    let searchFilters = filter;
    let linker = link;
    let searchFrom = from;

    if (!filter) {
        searchFilters = NewsIconFilters.join("%20OR%20");
    }

    if (!link) {
        linker = LINKERS.AND;
    }

    if (!from || from === undefined) {
        searchFrom = 0;
    }

    let payload = `query1=${query1}&query2=${query2}&link=${linker}&from=${searchFrom}`;

    if (!query2) {
        payload = `query1=${query1}&from=${searchFrom}`;
    }

    const res = await fetchPost(`${SEARCH_API_URL}/search-tag-across-fields`, {
        searchFilters,
        payload,
        filterForLast7Days,
    });

    res.total = res.total?.value || 0;
    return res;
};

export const useSearchTagsAcrossFields = (searchPayload: searchByTagsPayload) => {
    return useInfiniteQuery({
        queryKey: ["searchTagsAcrossFields", searchPayload],
        queryFn: ({ pageParam = 0 }) =>
            searchTagsAcrossFields({ ...searchPayload, from: pageParam as number }),
        getNextPageParam: (lastPage, allPages) => {
            const totalResults = lastPage?.total?.value || 0; // Total number of available results
            const fetchedResults = allPages.flatMap((page) => page.results).length;

            return fetchedResults < totalResults ? allPages.length * 30 : undefined;
        },
        initialPageParam: 0,
        enabled: !!searchPayload.query1 && !!isEmpty(searchPayload.filterForLast7Days),
    });
};

export const getUserByEmail = async ({ email }: { email: string }) => {
    const res = await fetchPost(`${SEARCH_API_URL}/search-user-by-email`, { email });
    return res;
};

export const useUserByEmail = (email: string) => {
    const queryKey = ["userByEmail", email];
    return useQuery({
        queryKey,
        queryFn: () => getUserByEmail({ email }),
    });
};

import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import MemoLogo from "@assets/SVG/Logo/logo";

export default function EODMarketSummary() {
    const content =
        "The crypto markets closed on a strong note today, buoyed by positive developments in the regulatory and technological spheres. The recent approval of a Bitcoin ETF by U.S. regulators has signaled a potential influx of institutional investment, opening new growth opportunities for the crypto market. Bitcoin surged to new all-time highs as a result, reinforcing positive market sentiment. Ethereum's latest network upgrade further boosted confidence, marking a significant milestone in improving the network's scalability and developer experience. Meanwhile, Ripple's partial victory in its lawsuit against the SEC has set a precedent for how digital assets may be classified, bringing optimism to XRP supporters and other crypto projects. The decentralized finance (DeFi) sector also saw continued growth, with total value locked (TVL) surpassing $100 billion, driven by the increased adoption of decentralized exchanges and lending protocols. Overall, market sentiment remained positive due to favorable regulatory developments, technological upgrades, and growing institutional interest in the crypto ecosystem.";

    return (
        <div className="relative flex size-full grow flex-col overflow-hidden bg-[#1C1F26]">
            <div className="flex h-11 w-full justify-between border-b border-royal-blue px-2 py-1.5">
                <p className="flex items-center text-[14px] font-normal leading-6">
                    End of Day Market Summary
                </p>
                <div className="flex items-center gap-1">
                    <MemoLogo className="w-2" />
                    <p className="text-[9px] font-light leading-3 text-neutral">BlockBeat AI</p>
                    <ExclamationCircleIcon className="size-2 text-light-gray" />
                </div>
            </div>

            <div className="flex size-full flex-col text-sm">
                <div className="flex w-full items-center justify-center gap-3 border-b border-deep-blue py-2 text-[10px] font-light leading-5 text-light-gray">
                    <p>Last Update: Yesterday at 7PM EST</p>
                    <p> | </p>
                    <p>Next Update: In 2h 43m 23s</p>
                </div>
                <div className="border-b border-deep-blue py-2 text-center text-lg font-normal leading-6 text-white">
                    Crypto Markets Close Strong as Positive Developments Boost Sentiment
                </div>
                <div className="scrollbar-hide flex w-full grow flex-col overflow-y-scroll px-2 py-1 font-light text-light-blue">
                    <div className="news-detail-gradient absolute bottom-0 left-0 h-40 w-full" />
                    <p
                        className="mb-36 list-disc space-y-2 pt-1.5 font-light text-light-blue"
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </div>
            </div>
        </div>
    );
}

"use client";

import { News } from "@/types/api";
import FeaturedNews from "@app/dashboard/FeaturedNews";
// import { SymbolLabel } from "@app/TSWidgets/components";
// import MainChart from "@app/TSWidgets/components/MainChart/MainChart";
// import ChartContainer2 from "@app/dashboard/[articleId]/news-detail/ChartContainer2";
import { isEmpty, uniq } from "lodash";
import EODMarketSummary from "./EODMarketSummary";
import { vercelEnv } from "@/services/environment";
import { useBookmarks } from "@/services/bookmarks";
import { useFetchNewsByIconFilter } from "@/services/search";
import { unwantedAssets } from "@/types/util";
import ComingSoon from "./ComingSoon";

export function Dashboard() {
    const { data: bookmarks } = useBookmarks();
    const { data: newsByIcon } = useFetchNewsByIconFilter({});

    const allNewsByIcon = {
        results: newsByIcon?.pages.flatMap((page) => page.results) || [],
        total: newsByIcon?.pages[0].total,
    };

    const results: News[] = allNewsByIcon?.results;

    // for now, Disable EIN Press as its the wrong feed - requested by Isaac on 23/04/2024
    const newsNotEIN = results.filter((r) => r.source !== "EIN");

    const randomNews = Math.floor(Math.random() * results.length);

    let viralNews;

    if (!isEmpty(newsNotEIN)) {
        viralNews = results[randomNews];
    } else {
        viralNews = results[0];
    }

    const renderedAssets = viralNews?.assets.filter((item) => !unwantedAssets.includes(item.name));

    viralNews = { ...viralNews, assets: renderedAssets };

    // dummy way to render the line-clamp figures owing to tailwind's jwt concept
    const lff = [
        "line-clamp-1",
        "line-clamp-2",
        "line-clamp-3",
        "line-clamp-4",
        "line-clamp-5",
        "line-clamp-6",
        "line-clamp-7",
        "line-clamp-8",
        "line-clamp-9",
        "line-clamp-10",
        "line-clamp-11",
        "line-clamp-12",
        "line-clamp-13",
        "line-clamp-14",
        "line-clamp-15",
        "line-clamp-16",
        "line-clamp-17",
        "line-clamp-18",
        "line-clamp-19",
        "line-clamp-20",
        "line-clamp-21",
        "line-clamp-22",
        "line-clamp-23",
        "line-clamp-24",
    ];

    return (
        <div className="fixed flex h-[calc(100vh-68px)] w-[inherit] flex-col gap-2 overflow-y-hidden">
            <FeaturedNews
                news={{
                    ...viralNews,
                    read: false,
                    publishedAt: viralNews?.publishedAt
                        ? viralNews.publishedAt
                        : viralNews?.NK
                          ? new Date(viralNews?.NK as number)?.toISOString()
                          : new Date(Date.now()).toISOString(), //2023-07-19T10:55:53.813Z
                    bookmarked: uniq(bookmarks).includes(viralNews?.SK as string),
                    slug: viralNews?.slug ? viralNews.slug : (viralNews?.SK as string),
                }}
            />
            <div className="h-[calc((100%-8px)/2)] w-full overflow-hidden rounded-lg border border-royal-blue bg-base-100">
                {/* <div
                className={`${
                    height > 1300
                        ? "h-[calc((100%-16px)/4)]"
                        : height > 1120
                        ? "h-[calc((100%-16px)/3)]"
                        : "h-[calc((100%-8px)/2)]"
                } w-full overflow-hidden rounded-lg border border-royal-blue bg-base-100`}
            > */}
                {vercelEnv === "production" ? (
                    <ComingSoon text="Charts Locked" />
                ) : (
                    // <div className="h-full overflow-hidden">
                    //     <ChartContainer2 />
                    // </div>
                    <EODMarketSummary />
                )}
            </div>
        </div>
    );
}

import { MemoExoticComponent, SVGProps, SetStateAction, useState } from "react";

export type RadioData = {
    icon?: MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => JSX.Element>;
    name: string;
    number?: string;
};

export default function RadioInput({
    defaultSelected,
    onChange,
    className,
    children,
    reverse = false,
}: {
    defaultSelected?: RadioData;
    onChange?: (e: SetStateAction<RadioData>) => void | any;
    className?: string;
    children: RadioData[];
    reverse?: boolean;
}) {
    const [selected, setSelected] = useState(defaultSelected?.name ? defaultSelected : children[0]);

    const handleSelected = (item: any) => {
        const thisNew = children.find((child: any) => child.name === item) as RadioData;

        setSelected(thisNew);
        if (onChange) {
            onChange(thisNew);
        }
    };

    return (
        <label className={`flex w-full cursor-pointer items-start ${className}`}>
            {children.map((item: any, i: number) => (
                <label
                    className={`${
                        reverse && "w-full flex-row-reverse"
                    } flex cursor-pointer items-center gap-2.5`}
                    onClick={() => handleSelected(item?.name)}
                    key={i}
                >
                    <input
                        type="radio"
                        name="radio-10"
                        className="radio bg-base-100 checked:bg-blue-500"
                        defaultChecked={item?.name === selected?.name}
                        value={item.name}
                    />
                    <div className="flex w-full items-center gap-2">
                        {item?.icon && (
                            <div className="flex size-9 items-center justify-center rounded-[50px] bg-white">
                                <item.icon className="size-5" />
                            </div>
                        )}
                        <div className="flex flex-col items-start justify-center p-0">
                            <p className="text-xs font-normal text-white">{item?.name}</p>
                            {item?.number && (
                                <p className="text-xs font-normal text-neutral">{item?.number}</p>
                            )}
                        </div>
                    </div>
                </label>
            ))}
        </label>
    );
}

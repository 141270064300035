import Vote from "@/components/vote/Vote";
import SentimentPopup from "@app/dashboard/SentimentPopup";
import NewItemTag from "@app/dashboard/news-feed/news-list/NewsItemTag";
import MemoBrain from "@assets/SVG/Brain";
import MemoFrowningEmoji from "@assets/SVG/Sentiment/FrowningEmoji";
import MemoNeutralEmoji from "@assets/SVG/Sentiment/NeutralEmoji";
import MemoSmilingEmoji from "@assets/SVG/Sentiment/SmilingEmoji";
import { isEmpty, maxBy, uniqBy } from "lodash";
import { PositiveBadge } from "@/components/badge/statistics";
import { News } from "@/types/api";

export const Headline = ({ news }) => {
    return (
        <h1 className="border-b border-deep-blue px-2 py-3 text-lg font-normal leading-6 text-white">
            {news?.headline}
        </h1>
    );
};

export const Tags = ({
    tags,
    addSearchItem,
}: {
    tags: {
        type: string;
        data: string;
    }[];
    addSearchItem: (tag: string, category: string) => void;
}) => {
    return (
        <div className="relative">
            <div className="scrollbar-hide flex max-w-full grow items-center gap-3 overflow-hidden overflow-x-auto scroll-smooth whitespace-nowrap">
                {tags?.map((tag, i) => (
                    <NewItemTag
                        tag={tag}
                        isRead={false}
                        isSelected={false}
                        key={i}
                        onClick={() => addSearchItem(tag.data, "tags")}
                    />
                ))}
            </div>
        </div>
    );
};

export const Actions = ({ sentimentData, iconType }: { sentimentData; iconType: string }) => {
    let sentimentIcon;
    let sentimentColor;

    if (!isEmpty(sentimentData)) {
        const highestSentiment = maxBy(Object.keys(sentimentData), (sentiment) => sentimentData[sentiment]);

        const highestIsPositive = highestSentiment === "positive";
        const highestIsNegative = highestSentiment === "negative";

        if (highestIsPositive) {
            sentimentIcon = "Positive";
            sentimentColor = "#27AF8F";
        } else if (highestIsNegative) {
            sentimentIcon = "Negative";
            sentimentColor = "#C83D4D";
        } else {
            sentimentIcon = "Neutral";
            sentimentColor = "#7A869B";
        }
    }

    const isPressRelease = iconType === "Press Release";
    const isTwitter = iconType === "Twitter";
    const isGovernment = iconType === "Government";

    const sentimentIsPositive = sentimentIcon === "Positive";
    const sentimentIsNeutral = sentimentIcon === "Neutral";
    const sentimentIsNegative = sentimentIcon === "Negative";

    const showAI = !isGovernment && !isPressRelease && !isTwitter;

    return (
        <div className="flex items-center justify-end gap-3 p-0">
            <div className="w-[fit-content]">
                <Vote decorated />
            </div>
            {!isPressRelease && <div className="grow" />}
            {showAI && (
                <div className="flex items-center justify-center gap-2 p-0">
                    <MemoBrain className="size-4" />
                    <p className="text-xs font-light text-neutral">A.I. Article Summary</p>
                </div>
            )}
            {!isEmpty(sentimentData) && !isPressRelease && <div className="h-4 w-px bg-dark-blue" />}
            {!isPressRelease && !isEmpty(sentimentData) && (
                <SentimentPopup sentimentData={sentimentData}>
                    <div className="flex items-center gap-0.5">
                        <div className="flex size-4 items-center justify-center">
                            {sentimentIsPositive && <MemoSmilingEmoji />}
                            {sentimentIsNeutral && <MemoNeutralEmoji />}
                            {sentimentIsNegative && <MemoFrowningEmoji />}
                        </div>
                        <p className={`text-xs font-light text-[${sentimentColor}]`}>{sentimentIcon}</p>
                    </div>
                </SentimentPopup>
            )}
        </div>
    );
};

export const getUpdatedURL = (pathname, params, key, value) => {
    // Clone the current query object to avoid mutating the original
    const updatedQuery = { ...params, [key]: value, chartType: "mentioned" };

    // Construct the new URL using the current pathname and the updated query object
    const newURL = {
        pathname: pathname,
        query: updatedQuery,
    };

    return newURL;
};

export const ChangeSincePublished = ({
    news,
    assets,
    updatedURL,
    addSearchItem,
}: {
    news: News;
    assets: any[];
    updatedURL: {
        pathname: any;
        query: any;
    };
    addSearchItem: (tag: string, category: string) => void;
}) => {
    const uniqAssets = uniqBy(assets, "slug");
    const isPressRelease = news.iconType === "Press Release";
    const isTwitter = news.iconType === "Twitter";

    if (uniqAssets?.length < 1 || isPressRelease) return <div />;

    return (
        <div
            className={`flex w-full items-center justify-between border-deep-blue ${
                isTwitter ? "border-b px-2 pb-2" : "border-y p-2"
            }`}
        >
            <div className="relative flex w-[calc(100%-80px)] items-center gap-2">
                <p className="w-[65px] shrink-0 text-[10px] font-light leading-3 text-neutral">
                    Change since published
                </p>
                <div className="relative flex w-full gap-1">
                    <div className="horizontal-scroll-gradient h-6.5 absolute -bottom-px -left-2 w-3 rotate-180" />
                    <div className="scrollbar-hide flex w-full items-center gap-1 overflow-x-scroll scroll-smooth px-[0.4rem]">
                        {uniqAssets?.map((asset: any) => (
                            <div key={asset?.slug} onClick={() => addSearchItem(asset.symbol, "assets")}>
                                <PositiveBadge asset={asset} from={news.publishedAt} />
                            </div>
                        ))}
                    </div>
                    <div className="horizontal-scroll-gradient h-6.5 absolute -bottom-px -right-1.5 w-3" />
                </div>
            </div>
            {/* <Link href={updatedURL} className="cursor-pointer text-xs font-normal text-primary">
                See on Chart
            </Link> */}
        </div>
    );
};

export const NewsContent = ({ news }) => {
    const processedContent = (news?.content || news?.headline)?.replace(/\n/g, "<br>");
    const processedEnrichment = news?.enrichment?.content?.replace(/\n/g, "<br>");

    return (
        <div className="scrollbar-hide flex grow flex-col overflow-y-scroll px-2 py-1">
            <div className="news-detail-gradient absolute bottom-0 left-0 h-40 w-full" />
            <p
                className="mb-2 list-disc space-y-2 pt-1.5 text-justify text-sm font-light text-light-blue"
                dangerouslySetInnerHTML={{ __html: processedContent }}
            />
            <p
                className="mb-12 list-disc pb-[4.5rem] pt-1.5 text-justify text-sm font-light text-light-blue"
                dangerouslySetInnerHTML={{ __html: processedEnrichment }}
            />
        </div>
    );
};

import MemoSmilingEmoji from "@assets/SVG/Sentiment/SmilingEmoji";
import { useContext, useEffect, useState } from "react";
import MemoCopy from "@assets/SVG/Copy";
import Vote from "@/components/vote/Vote";
import { ShareModal } from "@app/dashboard/[articleId]/news-detail/ShareModal";
import SourceIcon from "@/components/source/SourceIcon";
import MemoBrain from "@assets/SVG/Brain";
import { CurrencyDollarIcon, ShareIcon } from "@heroicons/react/24/outline";
import format from "date-fns/format";
import MemoNeutralEmoji from "@assets/SVG/Sentiment/NeutralEmoji";
import MemoFrowningEmoji from "@assets/SVG/Sentiment/FrowningEmoji";
import { useCopyToClipboard, useWindowSize } from "react-use";
import Alert from "@/components/Alert";
import MemoFlame from "@assets/SVG/Flame";
import NewsItemContext from "@app/dashboard/news-feed/news-list/NewsItemContext";
import Link from "next/link";
import { compact, isEmpty, maxBy, uniq, uniqBy } from "lodash";
import { Tags } from "@app/dashboard/[articleId]/news-detail/NewsDetailUtils";
import isYesterday from "date-fns/isYesterday";
import isToday from "date-fns/isToday";
import { SymbolLabel } from "@app/TSWidgets/components/SymbolLabel";
import {
    extractStringsFromArray,
    getArticleSource,
    transformStringsToArrayOfObjects,
    UpgradeSection,
} from "@/types/util";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon as BookMarkSolid } from "@heroicons/react/24/solid";
import { useCreateBookmarkMutation, useDeleteBookmarkMutation } from "@/services/bookmarks";
import SentimentPopup from "@app/dashboard/SentimentPopup";
import { outOfContextProxy } from "@/store/searchStore";
import { UpgradeModal } from "@app/dashboard/news-feed/news-list/UpgradeModal";
import { PaymentFeedbackModal } from "@app/profile/subscription/PaymentFeedbackModal";
import { useUserSubscription } from "@/services/payment";
import { PaymentNetworkModal } from "@app/profile/subscription/crypto/PaymentNetworkModal";
import { useStytchUser } from "@stytch/nextjs";
import { trackEvent } from "@app/analytics/mixpanel";

export default function NewsDashboardDetail() {
    const news = useContext(NewsItemContext);
    const [show, setShow] = useState(false);
    const [numberOfLines, setNumberOfLines] = useState(3);
    const { height } = useWindowSize();
    const publishedAt = new Date(news?.publishedAt);
    const newsSource = getArticleSource(news.source);
    const { data: subscription } = useUserSubscription();
    const { user } = useStytchUser();
    const userIsPro = subscription?.subIsActive;
    const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const { mutate: createBookmark } = useCreateBookmarkMutation();
    const { mutate: deleteBookmark } = useDeleteBookmarkMutation();

    let sentiment;
    let sentimentColor;
    const sentimentData = news?.data?.sentiment;

    if (!isEmpty(sentimentData)) {
        const highestSentiment = maxBy(Object.keys(sentimentData), (sentiment) => sentimentData[sentiment]);

        const highestIsPositive = highestSentiment === "positive";
        const highestIsNegative = highestSentiment === "negative";

        if (highestIsPositive) {
            sentiment = "Positive";
            sentimentColor = "#27AF8F";
        } else if (highestIsNegative) {
            sentiment = "Negative";
            sentimentColor = "#C83D4D";
        } else {
            sentiment = "Neutral";
            sentimentColor = "#7A869B";
        }
    }

    const addSearchItem = (tag: string, category: string) => {
        outOfContextProxy.tag = tag;
        outOfContextProxy.category = category;
    };

    const handleAddBookmark = () => {
        setAlertMessage("Article Saved");
        setShowAlert(true);
        createBookmark({
            slug: news.slug,
            url: news.url as string,
        });
        trackEvent("Bookmark", {
            slug: news.slug,
            user_id: user?.user_id,
        });
    };

    const handleDeleteBookmark = () => {
        setAlertMessage("Article Unsaved");
        setShowAlert(true);
        deleteBookmark({
            slug: news.slug,
        });
    };

    useEffect(() => {
        const textContainer = document.querySelector(".text-div");

        const lineHeight = 20; //1.25rem

        if (textContainer) {
            const containerHeight = parseFloat(window.getComputedStyle(textContainer).height);
            const numLines = Math.floor((containerHeight - 8) / lineHeight); //the 8 is the padding on top
            setNumberOfLines(numLines);
        }
    }, [height]);

    const [value, copy] = useCopyToClipboard();
    const [showAlert, setShowAlert] = useState(false);
    const [showAl, setShowAl] = useState(false);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const dateIsYesterday = isYesterday(publishedAt);
    const dateIsToday = isToday(publishedAt);

    let renderedDate;

    if (dateIsToday) {
        renderedDate = `${format(publishedAt, "paa")}`;
    } else if (dateIsYesterday) {
        renderedDate = `Yesterday, ${format(publishedAt, "paa")}`;
    } else {
        if (Number(format(publishedAt, "Y")) === currentYear) {
            renderedDate = `${format(publishedAt, "paa")}, ${format(publishedAt, "MMM do")}`;
        } else {
            renderedDate = `${format(publishedAt, "paa")}, ${format(publishedAt, "MMM do Y")}`;
        }
    }

    const extractedStrings = uniq(extractStringsFromArray(news?.tagsData || news?.tags));

    const transformedTags: {
        type: string;
        data: string;
    }[] = compact(transformStringsToArrayOfObjects(extractedStrings));

    // a case where the tag is a full object on its own
    const tagsWithType = news?.tags?.filter((tag) => tag?.type) || [];
    const renderedTags = uniqBy([...transformedTags, ...tagsWithType], (tag) => tag?.data.toLowerCase());

    const [alertMessage, setAlertMessage] = useState("");

    let newsContent = `${news?.content || news?.headline}\n\n${news?.enrichment?.connect}`;

    if (!news?.enrichment?.content) newsContent = news?.content || news?.headline;

    if (!news) return null;

    const isPressRelease = news.iconType === "Press Release";
    const isTwitter = news?.iconType === "Twitter";

    const sentimentIsPositive = sentiment === "Positive";
    const sentimentIsNeutral = sentiment === "Neutral";
    const sentimentIsNegative = sentiment === "Negative";

    return (
        <>
            {showPaymentFeedbackModal && (
                <PaymentFeedbackModal show={showPaymentFeedbackModal} setShow={setShowPaymentFeedbackModal} />
            )}

            {show && (
                <ShareModal
                    news={news}
                    show={show}
                    setShow={setShow}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                />
            )}

            <UpgradeModal
                show={showUpgradeModal}
                setShow={setShowUpgradeModal}
                setShowPaymentFeedbackModal={setShowPaymentFeedbackModal}
                message={UpgradeSection.Bookmark}
            >
                <></>
            </UpgradeModal>
            <Alert
                show={showAlert}
                setShow={setShowAlert}
                content={alertMessage ?? "Headline copied to clipboard."}
            />
            <PaymentNetworkModal currency="USD" show={showAl} setShow={setShowAl} />
            <div className="flex items-center justify-between border-b border-royal-blue px-2 py-1.5">
                <div
                    className="flex cursor-pointer items-center gap-2 hover:brightness-75"
                    onClick={() => setShowAl(true)}
                >
                    <SourceIcon
                        className={`h-6 w-6 ${isTwitter && "rounded-full bg-black"}`}
                        iconType={news?.iconType}
                    />
                    <div className="flex flex-col">
                        <p className="flex gap-1 text-sm font-normal leading-4 text-white">
                            <span>{newsSource}</span> {news?.ai?.hot && <MemoFlame />}
                        </p>
                        <p className="text-left text-xs font-light text-neutral">{renderedDate}</p>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex items-center gap-[17.5px] text-neutral">
                        <div className="relative self-center">
                            {news.assets?.length > 0 && !isPressRelease && (
                                <div
                                    className="z-0 m-0 flex cursor-pointer items-center gap-1 hover:brightness-75"
                                    onClick={() => addSearchItem(news?.assets?.[0]?.symbol, "assets")}
                                >
                                    <CurrencyDollarIcon className="size-4" color={"#D9CC5B"} />

                                    <div className="cursor-pointer whitespace-nowrap text-[10px] font-light uppercase leading-3 text-light-yellow">
                                        <SymbolLabel Symbol={news?.assets?.[0]?.symbol} />
                                        {news?.assets?.length > 1 && <> +{news?.assets?.length}</>}
                                    </div>
                                </div>
                            )}
                        </div>
                        {renderedTags?.length > 0 && !isPressRelease && (
                            <Tags tags={renderedTags} addSearchItem={addSearchItem} />
                        )}
                        <div className="h-0 w-4 rotate-90 border border-solid border-dark-slate-blue" />
                        <div
                            onClick={() => {
                                copy(news.headline + " - from BlockBeat.io");
                                setAlertMessage("Headline copied to clipboard.");
                                trackEvent("Headline Copy", {
                                    headline: news.headline,
                                    slug: news.slug,
                                    user_id: user?.user_id,
                                });
                                setShowAlert(false);
                                setShowAlert(true);
                            }}
                        >
                            <MemoCopy className="size-3.5 cursor-pointer hover:brightness-75" />
                        </div>
                        {news.bookmarked ? (
                            <BookMarkSolid
                                onClick={() => {
                                    if (!userIsPro) {
                                        return setShowUpgradeModal(true);
                                    }
                                    handleDeleteBookmark();
                                }}
                                strokeWidth={2.5}
                                className="size-3.5 cursor-pointer hover:brightness-75"
                            />
                        ) : (
                            <BookmarkIcon
                                onClick={() => {
                                    if (!userIsPro) {
                                        return setShowUpgradeModal(true);
                                    }
                                    handleAddBookmark();
                                }}
                                strokeWidth={2.5}
                                className="size-3.5 cursor-pointer hover:brightness-75"
                            />
                        )}
                        <ShareIcon
                            onClick={() => setShow(true)}
                            className="size-3.5 cursor-pointer hover:brightness-75"
                            strokeWidth={2.5}
                        />
                    </div>
                </div>
            </div>
            <div className="h-[calc(100%-109px)] grow">
                <div className="flex flex-col border-b border-royal-blue px-2 py-3">
                    <h1 className="line-clamp-2 pb-0 text-lg font-normal leading-6 text-white">
                        {news?.headline}
                    </h1>
                </div>

                <div className="text-div z-0 h-[calc(100%-72px)] grow px-2 pt-2">
                    <p
                        className={`line-clamp-${numberOfLines} text-justify text-sm font-light text-light-blue`}
                        dangerouslySetInnerHTML={{ __html: newsContent }}
                    />
                </div>
            </div>

            <div className="z-10 flex flex-col rounded-b-lg bg-base-100 px-2 pt-1">
                <div className="flex w-full flex-col gap-3">
                    <div className="flex items-center justify-between gap-2 p-0">
                        <div className="w-[fit-content]">
                            <Vote decorated />
                        </div>
                        <div className="relative flex items-center gap-3 p-0">
                            <div className="flex items-center justify-center gap-2 p-0">
                                <MemoBrain className="size-4" />
                                <p className="text-xs font-light text-neutral">A.I. Article Summary</p>
                            </div>

                            {!!sentiment && <div className="h-4 w-px bg-dark-blue" />}
                            {!!sentiment && (
                                <SentimentPopup sentimentData={sentimentData}>
                                    <div className="flex items-center gap-0.5">
                                        <div className="flex size-4 items-center justify-center">
                                            {sentimentIsPositive && <MemoSmilingEmoji />}
                                            {sentimentIsNeutral && <MemoNeutralEmoji />}
                                            {sentimentIsNegative && <MemoFrowningEmoji />}
                                        </div>
                                        <p className={`text-xs font-light text-[${sentimentColor}]`}>
                                            {sentiment}
                                        </p>
                                    </div>
                                </SentimentPopup>
                            )}
                        </div>
                    </div>
                </div>
                <Link href={`/dashboard/${news.slug}?chart=${"btc"}`} legacyBehavior>
                    <div className="flex cursor-pointer flex-col items-center justify-center hover:brightness-75">
                        <h1 className="pb-0 text-sm font-normal leading-6 text-primary">Read More</h1>
                    </div>
                </Link>
            </div>
        </>
    );
}

import { Dispatch, MemoExoticComponent, SetStateAction, SVGProps, useState } from "react";
import { Modal as ReactModal } from "react-responsive-modal";
import { Button } from "@/components";
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import RadioInput from "@/components/form-elements/Radio";
import MemoBitcoin from "@assets/SVG/Coins/Bitcoin";

export const PaymentNetworkModal = ({
    currency,
    show,
    setShow,
}: {
    currency: string;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) => {
    const paymentNetworks = [
        {
            icon: MemoBitcoin,
            name: "Bitcoin",
            number: "BTC",
        },
    ];

    const [network, setNetwork] = useState(paymentNetworks?.[0]);

    const handleChange = (
        e: SetStateAction<{
            icon?: MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => JSX.Element>;
            name: string;
            number?: string;
        }>,
    ) => {
        setNetwork(
            e as {
                icon: MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => JSX.Element>;
                name: string;
                number: string;
            },
        );
    };

    const onCloseModal = () => setShow(false);

    return (
        <ReactModal
            open={show}
            onClose={onCloseModal}
            focusTrapped={false}
            center
            closeIcon={<XMarkIcon className="size-4 text-neutral" strokeWidth={2.5} />}
            classNames={{
                modal: "modal-bg-none min-w-100 modal-radius-5 modal-padding-0 modal-margin-0",
            }}
        >
            <div className="w-100 flex flex-col items-center justify-center gap-3 overflow-hidden rounded-lg border-none bg-slate-blue p-0 pb-3">
                <div className="flex w-full flex-col items-center gap-0.5 border-b border-royal-blue px-4 py-3">
                    <div className="flex w-full flex-col p-0">
                        <div className="flex items-center gap-2 p-0">
                            <ArrowLeftIcon className="h-6 w-3 text-oxford-blue" />
                            <h2 className="text-base font-normal leading-5 text-white">Payment network</h2>
                        </div>
                        <p className="text-xs font-light text-neutral">
                            Select payment network for {currency}
                        </p>
                    </div>
                </div>
                <div className="flex w-full flex-col items-start gap-3 px-4 py-0">
                    <div className="flex w-full flex-col items-start gap-3 px-0 py-1">
                        <RadioInput
                            defaultSelected={paymentNetworks.find((m) => m?.name === network?.name)}
                            onChange={handleChange}
                            reverse={true}
                        >
                            {paymentNetworks}
                        </RadioInput>
                    </div>
                    <Button
                        onClick={() => setShow(false)}
                        disabled={!network}
                        className="flex h-10 w-full items-center justify-center gap-2.5 rounded-lg border-none bg-powder-blue px-2.5 py-3"
                    >
                        <p className="text-xs font-normal text-white">Continue</p>
                    </Button>
                </div>
            </div>
        </ReactModal>
    );
};
